import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

interface DateFilterFieldProps {
  name: string;
  label?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  max?: string;
  min?: string;
  defaultValue?: string;
}

export const DateFilterField: React.FC<DateFilterFieldProps> = ({ 
  name, 
  label, 
  isInvalid, 
  disabled, 
  max, 
  min, 
  defaultValue 
}) => {
  const [field, meta, helpers] = useField(name);

  // Format the date value for the input
  const formatDateValue = (value: string) => {
    if (!value) return '';
    try {
      const date = new Date(value);
      if (isNaN(date.getTime())) return value; // Return original if invalid date
      return date.toISOString().split('T')[0];
    } catch {
      return value;
    }
  };

  return (
    <Form.Group controlId={name} className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type="date"
        max={max}
        min={min}
        disabled={disabled}
        isInvalid={isInvalid || (meta.touched && !!meta.error)}
        value={formatDateValue(field.value || defaultValue || '')}
        onChange={(e) => helpers.setValue(e.target.value)}
        onBlur={field.onBlur}
      />
      <ErrorMessage
        name={name}
        component="span"
        className="text-danger fs-small"
      />
    </Form.Group>
  );
};