''

import { addDays, parse } from 'date-fns';
import * as Yup from 'yup';
import { Venue } from '../types/venue.type';
import { Course } from '../types/course.type';
import { Lesson } from '../types/lesson.type';
import { LessonPlan, Recipe } from '../types/lessonPlan.type';
import { User } from '../services/endpoints/people/user';
import { MenuOption } from '../services/helper';
import { userCanView_Recipes } from '../features/dash/views/content/Recipes/RecipesView';
import { userCanEditCreateDeleteView_Themes } from '../features/dash/views/content/Themes/ThemesView';
import { userCanEditCreateDeleteView_JokeCategories } from '../features/dash/views/content/JokeCategories/JokeCategoriesView';
import { userCanEditCreateDeleteView_Joke } from '../features/dash/views/content/Jokes/JokesView';
import { userCanEditCreateDeleteView_FeaturedIngredients } from '../features/dash/views/content/FeaturedIngredients/FeaturedIngredientsView';
import { userCanEditCreateDeleteView_CountryFacts } from '../features/dash/views/content/CountryFacts/CountryFactsView';
import { userCanEditCreateDeleteView_FoodHistories } from '../features/dash/views/content/FoodHistories/FoodHistoriesView';
import { userCanEditCreateDeleteView_KitchenSkills } from '../features/dash/views/content/KitchenSkills/KitchenSkillsView';
import { userCanEditCreateDeleteView_Questions } from '../features/dash/views/content/Questions/QuestionsView';
import { userCanEditCreateView_Venues } from '../features/dash/views/places/venues/VenuesView';
import { userCanEditCreateView_Organizations } from '../features/dash/views/places/organizations/OrganizationsView';
import { userCanView_TrainingVideos } from '../features/dash/views/training/TrainingVideo/TrainingVideosView';
import { userCanView_NewsItems } from '../features/dash/views/website/NewsItems/NewsItemsView';
import { userCanEditCreateDelete_Pages, userCanView_Pages } from '../features/dash/views/website/Pages/PagesView';
import { userCanView_Snippets } from '../features/dash/views/website/Snippets/SnippetsView';
import { userCanView_TeamProfiles } from '../features/dash/views/website/TeamProfiles/TeamProfilesView';
import { userCanView_Feedbacks } from '../features/dash/views/website/Feedbacks/FeedbacksView';
import { userCanView_Galleries } from '../features/dash/views/website/Galleries/GalleriesView';
import { userCanView_Sessions } from '../features/dash/views/schedule/sessions/SessionsView';
import { userCanView_Classes } from '../features/dash/views/schedule/classes/ClassesView';
import { userCanEditCreateDeleteView_Waitlist } from '../features/dash/views/schedule/waitlist/WaitlistView';
import { userCanView_Needs } from '../features/dash/views/schedule/needs/NeedsView';
import { userCanView_NeedSubstitute } from '../features/dash/views/schedule/needs/NeedSubstituteView';
import { userCanView_Instructors } from '../features/dash/views/people/InstructorsView';
import { userCanView_AdminUser } from '../features/dash/views/people/AdminsView';
import { userCanView_ParentUser } from '../features/dash/views/people/ParentsView';
import { userCanView_Children } from '../features/dash/views/people/Children/ChildrenView';
import { userCanView_Allergies } from '../features/dash/views/people/Allergy/AllergiesView';
import { userCanView_Waviers } from '../features/dash/views/people/Waiver/WaiversView';
import { userCanAdmin_Invoices } from '../features/dash/views/finance/Invoices/InvoicesView';
import { userCanView_CurrentEntries } from '../features/dash/views/finance/CurrentEntries/CurrentEntriesView';
import { userCanAdmin_Expenses } from '../features/dash/views/finance/Expenses/ExpensesView';
import { userCanAdmin_Receipts } from '../features/dash/views/finance/Receipts/ReceiptsView';
import { userCanViewEdit_Payments } from '../features/dash/views/finance/Payments/PaymentsView';
import { userCanViewRemoveSubmit_Orders } from '../features/dash/views/orders/OrdersView';
import { userCanAdmin_Refunds } from '../features/dash/views/orders/Refunds/RefundsView';
import { userCanAdmin_Scolarships } from '../features/dash/views/orders/Scholarships/ScholarshipsView';
import { userCanAdmin_Discounts } from '../features/dash/views/orders/Discounts/DiscountsView';
import { userCanView_PromotionalCodes } from '../features/dash/views/orders/PromotionalCodes/PromotionalCodesView';
import { userCanViewMasterTimesheet } from '../features/dash/views/finance/MasterTimesheetView';

export const getPartition = (id?: number): string => {
  if (!id) return '_';
  let id_partition = String(id).padStart(9, '0');
  return [id_partition.slice(0,3), id_partition.slice(3,6), id_partition.slice(6,9)].join('/');
}

export const getKeyByValue = <T, K extends keyof T>(obj: T, value: T[K]): K | undefined => {
  const entry = Object.entries(obj!).find(([key, val]) => val === value);
  return entry ? (entry[0] as K) : undefined;
};

export const getNestedTouchedTrue = (schema: Yup.ObjectSchema<any>) => {
  let s = schema.getDefault();
  Object.keys(s).forEach((k) => {
    s[k] = true;
  });
  return s;
}

export const getObjectDifference = (obj_1:any, obj_2:any) => {
  let obj1 = JSON.parse(JSON.stringify(obj_1));
  let obj2 = JSON.parse(JSON.stringify(obj_2));
  var result: {[key: string | number]: any} = {};
  let jsonEqual = JSON.stringify(obj1) === JSON.stringify(obj2);
  
  if (jsonEqual) {
    return result;
  }
  for(var key in obj1) {
    if(obj2[key] !== obj1[key]) result[key] = obj2[key];
    if(Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
      let filtered = obj1[key].reduce((prev: any[], curr: any, index: number) => {
        let foundIdx = prev.findIndex((i: any) => i.id === curr.id);
        if (foundIdx !== -1) {
          let found = prev[foundIdx];
          if (JSON.stringify(found) === JSON.stringify(curr)) {
            prev.splice(foundIdx, 1);
          }
        }
        else {
          if (curr.id) {
            prev.push({id: curr.id, destroy: true})
          }
        }
        return prev;
      }, obj2[key]);
      result[key] = filtered;
      if (result[key].length === 0) {
        delete result[key];
      }
    }
    else if (typeof obj2[key] == 'object' && typeof obj1[key] == 'object') {
      var newObj = getObjectDifference(obj1[key], obj2[key]);
      result[key] = newObj;
      if (result[key]) {
        if (Object.keys(result[key]).length === 0) {
          delete result[key];
        }
      }
    }
  }
  return result;
};

export const getErrorMessage = (error: any) => {
  return (error.response
    && error.response.data
    && error.response.data.message)
    || error?.message
    || error?.data?.message
    || error?.error
    || error?.data?.error
    || error.toString();
};

export const formatTimeToAMPM = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
};

export const convertDaysToFull = (
  days: string[],
  startDate: string,
  endDate: string
): string => {
  const dayMap: { [key: string]: string } = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
  };

  const fullDays = days.map((day, index) => {
    const fullDayName = dayMap[day];
    const dayCount = countDayOccurrences(index, startDate, endDate);

    return dayCount > 1 ? `${fullDayName}s` : fullDayName;
  });

  return fullDays.join(', ');
};

export const countDayOccurrences = (
  day: number,
  startDate: string,
  endDate: string
): number => {
  let count = 0;
  let currentDate = parse(startDate, 'yyyy-MM-dd', new Date());
  const end = parse(endDate, 'yyyy-MM-dd', new Date());
  while (currentDate <= end) {
    if (currentDate.getDay() === day) {
      count++;
    }
    currentDate = addDays(currentDate, 1);
  }

  return count;
}

export const formatDateString = (date: string): string => {
  let d = String(date);
  d = d.includes("T") ? d.split("T")[0] : d;
  d = d.includes("-") ? d : "0000-00-00";
  let dateArr = d.split('-');
  if (dateArr.length !== 3)
    return date
  const [year, month, day] = dateArr.map(Number);
  return `${month}/${day}/${year}`;
};

export const formatUrl = (path: string, params: any): string => {
  if (typeof params !== 'object') {
    return path;
  }
  
  let url = path;
  const { scope, entries } = params
  const searchParams = new URLSearchParams({
    scope: scope || '',
    entries: entries ? String(entries) : '',
  });
  if (searchParams.toString()) {
    url += '?' + searchParams.toString();
  }
  return url;
};

export const formatTwoDates = (startDateString?: string, endDateString?: string) => {
  if (!startDateString || !endDateString) {
    return "Invalid date(s)";
  }
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // Check if both dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return "Invalid date(s)";
  }

  // Format options
  const dayOptions: any = { weekday: 'long', month: 'long', day: 'numeric' };
  const monthDayOptions: any = { month: 'long', day: 'numeric' };
  const yearOptions: any = { year: 'numeric' };

  // Format the output: "Day, Month Day - Month Day, Year"
  const formattedStartDate = startDate.toLocaleDateString('en-US', dayOptions);
  const formattedEndMonthDay = endDate.toLocaleDateString('en-US', monthDayOptions);
  const formattedEndYear = endDate.toLocaleDateString('en-US', yearOptions);

  return `${formattedStartDate} - ${formattedEndMonthDay}, ${formattedEndYear}`;
}

export const formatAddress = (venue?: Venue): string => {
  if (!venue) {
    return '';
  }

  return `${venue.address}, ${venue.city}, ${venue.state} ${venue.zip}`;
}

export const getClassesRemaining = (course: Course): Lesson[] => {
  let remainingLessons: Lesson[] = [];
  const mainStartsOn = new Date();
  if (course.lessons.length) {
    remainingLessons = course.lessons.filter((lesson) => {
      const lessonStartsOn = new Date(lesson.date);
      return lessonStartsOn >= mainStartsOn;
    });
  }
  return remainingLessons;
};

export const formatToCurrency = (amount: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const getRangeDate = (startDate: Date): { start: string, end: string } => {
  const start = startDate.toISOString().slice(0, 10);
  const end = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    0
  )
    .toISOString()
    .slice(0, 10);
  return { start, end };
}

export const splitArray = (arr: any[], chunkSize: number) => {
  const result = [];
  
  // Loop over the array and slice it into chunks of chunkSize
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  
  return result;
}

export const stripHtmlTags = (html: string) => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

export const generateFileUrlSegment = (id: number = 0, moduleString: string, size?: string) => {
  const reverseString = (str: string) => {
    return str.split('').reverse().join('');
  }

  let idAddress = id.toString();

  const idLength = idAddress.length;
  for (let i = 0; i < 9 - idLength; i++) {
    idAddress = '0' + idAddress;
  }

  idAddress = reverseString(idAddress);
  idAddress = idAddress.slice(0, 3) + '/' + idAddress.slice(3, 6) + '/' + idAddress.slice(6, 9);
  idAddress = reverseString(idAddress);

  return `${moduleString}/${idAddress}${size ? `/${size}` : ''}`;
}

export const getPrimaryRecipe = (lessonPlan: LessonPlan): Recipe | null => {
  const findPrimaryRecipe = lessonPlan.recipes.find(
    (recipe) => recipe.lesson_plan_recipes?.is_primary
  );
  return findPrimaryRecipe || null;
}

export const getRecipeImage = (recipe: Recipe, size?: string): string | null => {
  return recipe.recipe_images?.length
    ? `${generateFileUrlSegment(
      recipe.recipe_images[0].id,
      recipe.recipe_images[0].s3_base_url,
      size
    )}/${recipe.recipe_images[0].image_file_name}`
    : null;
}

export const parseRecipeSteps = (steps: string): { title: string; content: string; }[] => {
  const lines = steps.split('\n');
    const items: { title: string; content: string; }[] = [];
  
    for (let i = 0; i < lines.length; i += 2) {
      const title = lines[i]?.trim(); // Title is the first line
      const content = lines[i + 1]?.trim(); // Content is the second line
  
      if (title || content) {
        items.push({
          title: title || 'Untitled', // Provide a default if title is empty
          content: content || '', // Content may be empty
        });
      }
    }
    return items;
}

export const getAllowedTabsContent = (user: User | null) => {
  let contentTabs: MenuOption[] = [
    { title: "Lesson Plans", url: "/admin/content/lesson-plans" },
    { title: "Documents", url: "/admin/content/documents" },
    { title: "Videos", url: "/admin/content/videos" }
  ];

  if(userCanView_Recipes(user)) {
    contentTabs.push({ title: "Recipes", url: "/admin/content/recipes" });
  }

  if(userCanEditCreateDeleteView_Themes(user)) {
    contentTabs.push({ title: "Themes", url: "/admin/content/themes" });
  }

  if(userCanEditCreateDeleteView_JokeCategories(user)) {
    contentTabs.push({
      title: "Joke Categories",
      url: "/admin/content/joke-categories",
    });
  }

  if(userCanEditCreateDeleteView_Joke(user)) {
    contentTabs.push({ title: "Jokes", url: "/admin/content/jokes" });
  }

  if(userCanEditCreateDeleteView_FeaturedIngredients(user)) {
    contentTabs.push({
      title: "Featured Ingredients",
      url: "/admin/content/featured-ingredients",
    });
  }

  if(userCanEditCreateDeleteView_CountryFacts(user)) {
    contentTabs.push({
      title: "Country Facts",
      url: "/admin/content/country-facts",
    });
  }

  if(userCanEditCreateDeleteView_FoodHistories(user)) {
    contentTabs.push({
      title: "Food Histories",
      url: "/admin/content/food-histories",
    });
  }

  if(userCanEditCreateDeleteView_KitchenSkills(user)) {
    contentTabs.push({
      title: "Kitchen Skills",
      url: "/admin/content/kitchen-skills",
    });
  }

  if(userCanEditCreateDeleteView_Questions(user)) {
    contentTabs.push({
      title: "Questions",
      url: "/admin/content/questions",
    });
  }
  return contentTabs;
}

export const getAllowedTabsPlaces = (user: User | null) => {
  let placesTabs: MenuOption[] = [
    {
      title: "Locations",
      url: "/admin/places/locations",
    },
    {
      title: "Territories",
      url: "/admin/places/territories",
    },
    {
      title: "Zip Codes",
      url: "/admin/places/zip-codes",
    },
  ];

  if(userCanEditCreateView_Venues(user)) {
    placesTabs.push({
      title: "Venues",
      url: "/admin/places/venues",
    });
  }

  if(userCanEditCreateView_Organizations(user)) {
    placesTabs.push({
      title: "Organizations",
      url: "/admin/places/organizations",
    });
  }
  return placesTabs;
}

export const getAllowedTabsTraining = (user: User | null) => {
  let trainingTabs: MenuOption[] = [
    // {
    //   title: "Guides",
    //   url: "/admin/training/guides",
    // },
    // {
    //   title: "Steps",
    //   url: "/admin/training/steps",
    // },
    
  ];

  if(userCanView_TrainingVideos(user)) {
    trainingTabs.push({
      title: "Videos",
      url: "/admin/training/videos",
    });
  }
  return trainingTabs;
}

export const getAllowedTabsWebsite = (user: User | null) => {
  let websiteTabs: MenuOption[] = [
    // {
    //   title: 'Franchisee Leads',
    //   url: '/admin/website/franchisee-leads'
    // },
  ];

  if(userCanView_NewsItems(user)) {
    websiteTabs.push({
      title: "News Items",
      url: "/admin/website/news-items",
    });
  }

  if(userCanView_Pages(user)) {
    websiteTabs.push({
      title: "Pages",
      url: "/admin/website/pages",
    });
  }

  if(userCanView_Snippets(user)) {
    websiteTabs.push({
      title: "Snippets",
      url: "/admin/website/snippets",
    });
  }

  if(userCanView_TeamProfiles(user)) {
    websiteTabs.push({
      title: "Team Profiles",
      url: "/admin/website/team-profiles",
    });
  }

  if(userCanView_Feedbacks(user)) {
    websiteTabs.push({
      title: "Feedbacks",
      url: "/admin/website/feedbacks",
    });
  }

  if(userCanView_Galleries(user)) {
    websiteTabs.push({
      title: "Galleries",
      url: "/admin/website/galleries",
    });
  }

  return websiteTabs;
}

export const getAllowedTabsSchedule_SessionsClasses = (user: User | null) => {
  let sessionsTabs: MenuOption[] = [
  ];

  if(userCanView_Sessions(user)) {
    sessionsTabs.push({
      title: "Sessions",
      url: "/admin/schedule/sessions"
    });
  }

  if(userCanView_Classes(user)) {
    sessionsTabs.push({
      title: "Classes",
      url: "/admin/schedule/classes"
    });
  }

  if(userCanEditCreateDeleteView_Waitlist(user)) {
    sessionsTabs.push({
      title: "Waitlists",
      url: "/admin/schedule/waitlists"
    });
  }
  return sessionsTabs;
}

export const getAllowedTabsSchedule_ScheduleNeeds = (user: User | null) => {
  let scheduleTabs: MenuOption[] = [
    {
      title: "Instructor Session Requests",
      url: "/admin/schedule-needs/instructor-session-requests",
    },
  ];

  if(userCanView_Needs(user)) {
    scheduleTabs.push({
      title: "Instructor Needed",
      url: "/admin/schedule-needs/need-instructor",
    });
  }

  if(userCanView_NeedSubstitute(user)) {
    scheduleTabs.push({
      title: "Subs Needed",
      url: "/admin/schedule-needs/need-substitute",
    });
  }
  return scheduleTabs;
}

export const getAllowedTabsPeople_DashUsers = (user: User | null) => {
  let dashUsersTabs: MenuOption[] = [
    
  ];

  if(userCanView_AdminUser(user)) {
    dashUsersTabs.push({ title: "Admins", url: "/admin/dash-users/admins" });
  }

  if(userCanView_Instructors(user)) {
    dashUsersTabs.push({
      title: "Instructors",
      url: "/admin/dash-users/instructors",
    });
  }

  return dashUsersTabs;
}

export const getAllowedTabsPeople_SFCUsers = (user: User | null) => {
  let dashUsersTabs: MenuOption[] = [];

  if(userCanView_ParentUser(user)) {
    dashUsersTabs.push({ title: "Parents", url: "/admin/sfc-users/parents" });
  }

  if(userCanView_Children(user)) {
    dashUsersTabs.push({ title: "Children", url: "/admin/sfc-users/children" });
  }

  if(userCanView_Allergies(user)) {
    dashUsersTabs.push({
      title: "Allergies",
      url: "/admin/allergies",
    });
  }

  if(userCanView_Waviers(user)) {
    dashUsersTabs.push({
      title: "Waivers",
      url: "/admin/waivers",
    });
  }
  return dashUsersTabs;
}

export const getAllowedTabsFinance = (user: User | null) => {
  let financeTabs: MenuOption[] = [
    {
      title: "KPI Reports",
      url: "/admin/finance/powerBi",
    }
  ];

  if(userCanAdmin_Invoices(user)) {
    financeTabs.push({
      title: "Invoices",
      url: "/admin/finance/invoices",
    });
  }

  if(userCanView_CurrentEntries(user)) {
    financeTabs.push({
      title: "Current Entries",
      url: "/admin/finance/current-entries",
    });
  }

  if(userCanAdmin_Expenses(user)) {
    financeTabs.push({
      title: "Expenses",
      url: "/admin/finance/expenses",
    });
  }

  if(userCanAdmin_Receipts(user)) {
    financeTabs.push({
      title: "Receipts",
      url: "/admin/finance/receipts",
    });
  }

  if(userCanViewEdit_Payments(user)) {
    financeTabs.push({
      title: "Payments",
      url: "/admin/finance/payments",
    });
  }
  return financeTabs;
}

export const getAllowedTabsTimesheets = (user: User | null) => {
  let timesheetsTabs: MenuOption[] = [];

  if(userCanViewMasterTimesheet(user)){
    timesheetsTabs.push(
      {
        title: "Master Timesheet",
        url: "/admin/timesheets/master-timesheet",
      }
    )
  }

  timesheetsTabs.push(
    {
      title: `Current Entries`,
      url: "/admin/timesheets/current-timesheet",
    },
    {
      title: "Past Timesheets",
      url: "/admin/timesheets/past-timesheets",
    },
    {
      title: "Overrides",
      url: "/admin/timesheets/timesheet-overrides",
    }
  );

  return timesheetsTabs;
}

export const getAllowedTabsOrders = (user: User | null) => {
  let ordersTabs: MenuOption[] = [];

  if(userCanViewRemoveSubmit_Orders(user)) {
    ordersTabs.push({
      title: "Orders",
      url: "/admin/orders/orders",
    });
  }

  if(userCanAdmin_Refunds(user)) {
    ordersTabs.push({
      title: "Refunds",
      url: "/admin/orders/refunds",
    });
  }

  if(userCanAdmin_Scolarships(user)) {
    ordersTabs.push({
      title: "Scholarships",
      url: "/admin/orders/scholarships",
    });
  }

  if(userCanAdmin_Discounts(user)) {
    ordersTabs.push({
      title: "Discounts",
      url: "/admin/orders/discounts",
    });
  }

  if(userCanView_PromotionalCodes(user)) {
    ordersTabs.push({
      title: "Promotional Codes",
      url: "/admin/orders/promotional-codes",
    });
  }
  return ordersTabs;
}