import { FormValues } from '../../../features/dash/views/finance/Invoices/AddEditInvoice';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Course } from '../../../types/course.type';
import { Invoice } from '../../../types/finance.types';
import { sfcApi } from '../../api';
export interface ClientInvoicer {
  course_id:number
  invoiceable_id:  number
  invoiceable_type:       string
}
export interface ParentInvoicer {
  course_id:number
}
export interface ClientInvoicerValues {
  course_id:number
  invoiceable_id: number;
  invoiceable_type:       string
}

export interface AddRemitClient {
  course_id:number;
  invoice_client_id:number;
  
}

export interface AppendRemitClient {
  course_id:number;
  invoice_id:number;
  
}

export const invoicerApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoicers: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `invoice-clients${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Invoicer'],
    }),
    getInvoice: builder.query<{ invoice: Invoice }, number>({
      query: (id) => `invoices/${id}`,
      providesTags: (result, error, id) => [{ type: 'Invoice', id }]
    }),
    addClientInvoicer: builder.mutation<{ addClientInvoicer: ClientInvoicer }, ClientInvoicerValues>({
      query: (data) => {
        const { course_id } = data;
         const dataSave={
          invoiceable_id: data?.invoiceable_id,
          invoiceable_type:       data?.invoiceable_type
         }
        return {
          url: `courses/build-client/${course_id}`,
          method: 'POST',
          body: dataSave
        }
      },
      invalidatesTags: (result, error, { invoiceable_id }) => [{ type: 'Invoicer', invoiceable_id: invoiceable_id }, 'Invoicer']
    }),
    addParent: builder.mutation<{ addClientInvoicer: ParentInvoicer }, ParentInvoicer>({
      query: (data) => {
        const { course_id } = data;
    
        return {
          url: `courses/build-clients/${course_id}`,
          method: 'POST',
        };
      },
    }),
    
    addRemitInvoce: builder.mutation<{ addClientInvoicer: AddRemitClient }, AddRemitClient>({
      query: (data) => {
        const { course_id,invoice_client_id } = data;
        
        return {
          url: `/courses/remit/${course_id}`,
          method: 'POST',
           body: {invoice_client_id:invoice_client_id}
        }
      },
      invalidatesTags: (result, error, { invoice_client_id }) => [{ type: 'Invoicer', invoice_client_id: invoice_client_id }, 'Invoicer']
    }),
    appendRemitInvoce: builder.mutation<{ addClientInvoicer: AppendRemitClient }, AppendRemitClient>({
      query: (data) => {
        const { course_id,invoice_id } = data;
        
        return {
          url: `/courses/remit/${course_id}`,
          method: 'POST',
           body: {invoice_id:invoice_id}
        }
      },
      invalidatesTags: (result, error, { invoice_id }) => [{ type: 'Invoicer', invoice_id: invoice_id }, 'Invoicer']
    }),
    updateInvoice: builder.mutation<{ invoice: Invoice }, FormValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `invoices/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Invoice', id: result?.invoice.id }, 'Invoice']
    }),
    deleteInvoice: builder.mutation<{ invoice: Invoice }, number>({
      query: (id) => ({
        url: `invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Invoice', id }, 'Invoice'],
    }),
    getCourseInvoicer: builder.query<{ course: Course }, number>({
      query: (id) =>  `/courses/invoicer/${id}`,
      providesTags: (result, error, id) => [{ type: 'Invoice', id }]
    }),
  }),
});

export const {
  useGetAllInvoicersQuery,
  useGetInvoiceQuery,
  useAddClientInvoicerMutation,
  useAddParentMutation,
  useAddRemitInvoceMutation,
  useAppendRemitInvoceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetCourseInvoicerQuery
} = invoicerApi;