import React, { useCallback, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
// import { InvoiceItem } from "../../types/content.type";
import { FaGripVertical } from 'react-icons/fa';
import CurrencyCell from "./cellComponents/CurrencyCell";
import { Stack } from 'react-bootstrap';
import { LinkCell } from "./cellComponents/LinkCell";
import { userCanAdmin_Invoices } from "../../features/dash/views/finance/Invoices/InvoicesView";
import { getCurrentUser } from "../../services/helper";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import './InvoiceItems.css';
import { debounce } from 'lodash';
import { useMoveInvoiceItemsMutation } from "../../services/endpoints/finance/invoiceItem";

const currentUser = getCurrentUser();
// Define Invoice Item type
interface InvoiceItem {
  id: number;
  description: string;
  enrollment_id: number;
  unit_price: number;
  quantity: number;
}

// Drag and Drop Item Type
const DRAGGABLE_ITEM_TYPE = "row";

// Draggable Row Component
const DraggableRow = ({ item, index, moveRow, isPaidInvoice }: any) => {
  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM_TYPE,
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveRow(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <tr
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <td><FaGripVertical style={{ cursor: "grab" }} /></td>
      <td>{item.id}</td>
      <td>{item.description}</td>
      <td>
        <LinkCell
          content={`Enrollment ${item.enrollment_id}`}
          url={`/admin/finance/enrollments/${item.enrollment_id}`}
          underline
        />
      </td>
      <td><CurrencyCell value={item.unit_price} /></td>
      <td>{item.quantity}</td>
      <td><CurrencyCell value={item.unit_price * (item.quantity ?? 0)} /></td>
      <td>
        <Stack direction="horizontal" gap={2}>
          <LinkCell content="View" url={`/admin/finance/invoice-items/${item.id}`} underline />
          {userCanAdmin_Invoices(getCurrentUser()) && !isPaidInvoice && (
            <>
              <LinkCell content="Edit" url={`/admin/finance/invoice-items/${item.id}/edit`} underline />
              <LinkCell content="Delete" url={`/admin/finance/invoice-items/${item.id}/delete`} underline />
            </>
          )}
        </Stack>
      </td>
    </tr>
  );
};

interface InvoiceItemsProps {
  data: any;
  isPaidInvoice: boolean;
}

// Table used for expanded rows information
export const InvoiceItems: React.FC<InvoiceItemsProps> = ({
  data,
  isPaidInvoice
}) => {
  
  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    setTableData(data);
  }, [data]);

   const [ moveInvoice ] = useMoveInvoiceItemsMutation();
    
        const debouncedMoveRecipe = useCallback(
            debounce((updatedRows) => {
              moveInvoice({
                data: updatedRows.map((recipe: any, index: number) => ({
                  id: recipe.id,
                  position: index + 1,
                })),
              });
            }, 500),
            [moveInvoice]
          );
  // Move Row Function
  const moveRow = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedData = [...tableData];
      const [movedRow] = updatedData.splice(fromIndex, 1);
      updatedData.splice(toIndex, 0, movedRow);
      setTableData(updatedData);

      
      if (fromIndex !== toIndex) {
        debouncedMoveRecipe(updatedData);
      }
    },
    [tableData]
  );


  return (
    <DndProvider backend={HTML5Backend}>
      <div className="table-responsive">
        <table className="table sfc-table simple-table">
          <thead className="table-light">
            <tr>
              <th></th>
              <th>ID</th>
              <th>Description</th>
              <th>Enrollment</th>
              <th>Unit Price</th>
              <th>Quantity</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item: InvoiceItem, idx:any) => (
              <DraggableRow key={item.id} item={item} index={idx} moveRow={moveRow} isPaidInvoice={isPaidInvoice} />
            ))}
          </tbody>
        </table>
      </div>
    </DndProvider>
  );
};