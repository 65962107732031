import { Form, Stack } from 'react-bootstrap';
import { SimpleButton } from '../../buttons/SimpleButton';
import React, { ReactNode } from 'react';
import { Field, Formik, Form as FormikForm } from 'formik';
import { formatTimeToAMPM } from '../../../utils/utils';
import { FormSelectOptions } from './FormSelect';

export interface FilterField {
  name: string;
  id?: string;
  value?: string|FormSelectOptions[];
  type?: string;
  label?: string;
  operator?: string;
  element?: ReactNode;
  placeholder?: string;
}

interface FilterFormProps {
  fields: FilterField[];
  onApplyFilters: (filters: { operator: string, field: string, value: string }[]) => void;
  reset?: (originalReset: () => void) => void;
}

const operators: { [key: string]: string; } = {
  equals: 'Equals',
  contains: 'Contains',
  starts_with: 'Starts with',
  ends_with: 'Ends with',
  greater_than: 'Greater than',
  greater_equal_than: 'Greater or equal than',
  lesser_than: 'Less than',
  lesser_equal_than: 'Less or equal than',
  includes: 'Includes',
};

const textOperators = ['contains', 'equals', 'starts_with', 'ends_with'];
const numberOperators = ['equals', 'greater_than', 'lesser_than'];

const getDefaultOperator = (type: string | undefined) => {
  if (type === 'number') return numberOperators[0];
  return textOperators[0];
}

export const FilterForm: React.FC<FilterFormProps> = ({ fields, onApplyFilters, reset }) => {
  const initialValues: { [key: string]: string } = fields.reduce((obj, field) => ({ ...obj, [field.name]: field.value ?? '' }), {});
  const handleSubmit = (values: { [key: string]: any }) => {
    const filterByElements = Object.entries(values)
      .filter((field) => !(field[0].includes('-operator') || (!field[1] || field[1] === '' || field[1].value === '')))
      .map((field) => {
        const fieldFromList = fields.find((f) => (f.name) === field[0]);
        let operator = Object.keys(values).includes(`${field[0]}-operator`)
          ? values[`${field[0]}-operator`]
          : fieldFromList?.operator ?? getDefaultOperator(fieldFromList?.type);
        
        // Handle case where value is an object (like from Typeahead)
        if (field[1] && typeof field[1] === 'object' && field[1].value !== undefined) {
          field[1] = field[1].value;
        }
  
        // Handle array values
        if(Array.isArray(field[1])){
          let value = field[1].reduce((acc, curr) => curr.value, '');
          field[1] = value;
          operator = 'contains';
        }
  
        // Handle date range fields
        if (fieldFromList?.operator) {
          operator = fieldFromList.operator;
        }
  
        if (['starts_at', 'ends_at', 'formatted_time', 'time'].includes(field[0])) {
          return {
            operator,
            field: 'time',
            value: formatTimeToAMPM(field[1]),
          };
        }
        return {
          operator,
          field: fieldFromList?.id ?? field[0],
          value: field[1],
        };
      }
    );
    onApplyFilters(filterByElements);
  };

  const handleReset = () => {
    reset?.(() => {
      Object.keys(initialValues).forEach((key) => {
        initialValues[key] = '';
      });
    });
    handleSubmit(initialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onReset={handleReset}
      enableReinitialize
    >
      {({ handleSubmit, handleReset }) => (
        <FormikForm onSubmit={handleSubmit}>
          <Stack gap={3}>
            {fields.map((field, idx) => field.element
              ? <React.Fragment key={idx}>{field.element}</React.Fragment>
              : (
                <Stack key={idx} direction="horizontal" gap={3}>
                  <Form.Group controlId={`${field.name}-operator`} className="w-75">
                    <Form.Label>{field.label}</Form.Label>
                    <Field
                      as={Form.Select}
                      name={`${field.name}-operator`}
                    >
                      {(field.type === 'number' ? numberOperators : textOperators).map((op) => (
                        <option key={op} value={op} className="text-capitalize">
                          {operators[op]}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                  <Form.Group controlId={field.id ?? field.name} className="w-100">
                    <Form.Label />
                    <Field
                      as={Form.Control}
                      type={field.type}
                      name={field.id ?? field.name}
                      placeholder={field.placeholder ?? ''}
                    />
                  </Form.Group>
                </Stack>
              )
            )}
          </Stack>
    
          <Stack direction="horizontal" className="mt-3 border-top pt-3" gap={2}>
            <SimpleButton type="submit" className="w-100" variant="primary">
              Filter
            </SimpleButton>
            <SimpleButton type="reset" onClick={handleReset} className="w-100" variant="outline-primary">
              Clear Filters
            </SimpleButton>
          </Stack>
        </FormikForm>
      )}
    </Formik>
    
  )
}
