import { ButtonData } from '../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { ColumnDef, Getter, Row } from '@tanstack/react-table';
import { Scopes, SFCLocation, useGetInstructorsQuery, useMimicMutation, User } from '../../../../services/endpoints/people/user';
import { isSuper, userTypeNames, userTypes, usTimezones } from '../../../../utils/consts';
import { ExpanderCell } from '../../../../components/tables/cellComponents/ExpanderCell';
import { getErrorMessage, getKeyByValue } from '../../../../utils/utils';
import { BadgeCell } from '../../../../components/tables/cellComponents/BadgeCell';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useToast } from '../../../../context/ToastContext';
import { getCurrentUser } from '../../../../services/helper';
import { GetQueryParams } from '../../../../types/api.type';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { useDataContext } from '../../../../context';
import { userCanLoginAs } from './AdminsView';
import { DateCell } from '../../../../components/tables/cellComponents/DateCell';

interface DataRow {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  location: SFCLocation;
  user_type_id: number;
  active: string;
  children?: any[];
  last_paid_for: string;
  log_in_as: (id: number) => void;
}

const defaultScope = 'active_instructors';

export const userCanEditCreateDelete_Instructors = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Instructors = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const InstructorsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetInstructorsQuery(filters);
  const [users, setUsers] = useState<User[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [mimic] = useMimicMutation();
  const currentUser = getCurrentUser();
  const canMimic = userCanLoginAs(currentUser);

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'first_name',
      label: 'First Name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'zip',
      label: 'Zip',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'timezone',
      label: 'Timezone',
      element: (
        <FormSelect
          name="timezone"
          label="Timezone"
          options={usTimezones.map((tz) => ({ value: tz, label: tz }))}
        />
      ),
    },
  ];

  if(currentUser?.isGlobal) {
    filterFormFields.push({
      name: 'location_id',
      label: 'Location',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    });
  }
  
  const columns: ColumnDef<DataRow>[] = [
    {
      id: 'expander',
      header: () => null,
      enableSorting: false,
      cell: ({ row }) => row.original?.children && row.original?.children?.length > 0 ? (
        <ExpanderCell row={row} />
      ) : null,
    },
    {
      header: 'ID',
      accessorKey: 'id',
      cell: ({ getValue, row }) => (
        <LinkCell content={getValue<string>()} url={`/admin/dash-users/instructors/${row.original.id}`} />
      ),
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
      // cell: ({ getValue, row }) => (
      //   <LinkCell content={getValue<string>()} url={`/admin/dash-users/instructors/${row.original.id}`} />
      // ),
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Location',
      accessorKey: 'location',
      enableSorting: false,
      cell: ({ getValue }) => (
        <span className="">
          {getValue<SFCLocation>()?.title}
        </span>
      ),
    },
    {
      header: 'User Type',
      accessorKey: 'user_type_id',
      cell: ({ getValue }) => (
        <span className="">
          {userTypeNames['instructor']}
        </span>
      ),
    },
    {
      header: 'Last Paid For',
      accessorKey: 'last_paid_for ',
      cell: ({ getValue }) => (
        <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
      ),
    },
    {
      header: 'Active',
      accessorKey: 'active',
      cell: ({ getValue }) => (
        <BadgeCell active={getValue<boolean>()} />
      ),
    },
    ...(canMimic ? [{
      header: '',
      accessorKey: 'log_in_as',
      enableSorting: false,
      cell: ({ getValue, row }: { getValue: Getter<unknown>, row: Row<DataRow> }) => (
        <Button
          size="sm"
          variant="sfc-orange"
          className="text-truncate"
          onClick={() => getValue<(id: number) => void>()(row.original.id)}
        >
          Log in as...
        </Button>
      ),
    }] : []),
  ];

  const loginAs = (userId: number) => {
    mimic(userId).then((r) => {
      navigate("/");
    }, 
    (error: any) => {
      addToast(getErrorMessage(error), 'error');
    })
  };

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      const results = data.results.map((user) => ({
        ...user,
        log_in_as: loginAs,
      }))
      setUsers(results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };
  
  return (
    <div className="py-2">
      <SFCTable
        name={scopes[filters?.scope!]}
        columns={columns}
        data={users}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='users/instructors'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_Instructors(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={300}
            onClick={() => navigate('/admin/dash-users/instructors/new')}
          >
            New instructor
          </SimpleButton>
        )}
      />
    </div>
  )
}
