import React from 'react';
import DOMPurify from 'dompurify'; // For sanitizing HTML content

const SafeHTMLDisplay: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  const regex = /\{\{(.*?)\}\}/g;
  const boldRegex = /\*\*(.*?)\*\*/g; // Handle **bold text**

  if (!htmlContent) return <></>;

  const paragraphs = htmlContent.split('\n').map((line, index) => {
    let formattedLine = line.replace(regex, (match, value) => {
      return `<strong>${value}</strong>`;
    });

    formattedLine = formattedLine.replace(boldRegex, (match, value) => {
      return `<b>${value}</b>`;
    });

    const sanitizedHTML = DOMPurify.sanitize(formattedLine);
    return (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML.trim() }}
        style={{ marginBottom: '10px', lineHeight: '1.6', fontSize: '18px' }}
      />
    );
  });

  return <div>{paragraphs}</div>;
};

export default SafeHTMLDisplay;
