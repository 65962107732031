import { ErrorMessage, Field } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { debounce } from "lodash";

interface DateFieldProps {
  name: string;
  label?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  max?: string;
  min?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleSubmit?: () => void;
  default_Value?: string;
}

export const DateField : React.FC<DateFieldProps> = ({ name, label, isInvalid, disabled, max, min, handleSubmit, setFieldValue, default_Value }) => {
  const debouncedSubmit = useCallback(
    debounce(() => {
      if (!isInvalid) {
        handleSubmit!();
      }
    }, 1000),
    [handleSubmit, isInvalid]
  );

  const [dateValue, setDateValue] = useState('');

  useEffect(() => {
    if (default_Value === null || default_Value === undefined) return
    const inputDate = default_Value ?? "";
    
    const date = new Date(inputDate);
    const formattedDate = date.toISOString().split('T')[0];

    setDateValue(formattedDate);
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setDateValue(e.target.value);
  };
  return (
    <Form.Group controlId={name} className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      <Field name={name}>
        {({ field }: { field: any }) => (
          <Form.Control
            type="date"
            max={max}
            min={min}
            {...field}
            disabled={disabled}
            isInvalid={isInvalid}
            defaultValue={default_Value}
            value={dateValue} // Controlled value
            onChange={handleChange} // onChange handler to update the state
          />
        )}
      </Field>

      <ErrorMessage
        name={name}
        component="span"
        className="text-danger fs-small"
      />
    </Form.Group>
  );
}
