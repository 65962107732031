import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FaArrowLeft } from 'react-icons/fa6';
import { Accordion, Form, Spinner, Stack } from 'react-bootstrap';
import { SFCAccordion } from '../../../../../components/accordion/SFCAccordion';
import { SimpleTable } from '../../../../../components/tables/SimpleTable';
import { useToast } from '../../../../../context/ToastContext';
import { IconLink } from '../../../../../components/iconLink/IconLink';
import { BsCashCoin, BsCreditCard, BsFiletypePdf } from 'react-icons/bs';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { Invoice } from '../../../../../types/finance.types';
import { useDeleteInvoiceMutation, useGetInvoiceQuery, useSendInvoiceMutation, useWarnInvoiceMutation } from '../../../../../services/endpoints/finance/invoice';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Badge } from '../../../../../components/badge/Badge';
import { invoiceStatusColors } from '../../../../../utils/consts/finance/finance';
import { invoiceItemsColumns, invoiceSessionsColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { dateToUTC, formatDate } from '../../../../../utils/dateUtils';
import { getErrorMessage } from '../../../../../utils/utils';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import Button from 'react-bootstrap/Button';
import { userCanAdmin_Invoices } from './InvoicesView';
import { getCurrentUser } from '../../../../../services/helper';
import { revisionsColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { authHeaderToken } from '../../../../../services/auth-header';
import { downloadInvoice } from '../../../../../services/api';
import  {InvoiceItems} from '../../../../../components/tables/invoiceItems';
const LabelTextList = ({ label, list }: { label: string, list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: 'rgba(94, 100, 105, .5)' }}>{label}</span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">{text}</span>
    ))}
  </Stack>
);

export const InvoiceDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetInvoiceQuery(+params.id!);
  const [invoice, setInvoice] = useState<Invoice>();
  const [ deleteInvoice ] = useDeleteInvoiceMutation();
  const [ sendInvoice ] = useSendInvoiceMutation();
  const [ warnInvoice ] = useWarnInvoiceMutation();
  const [showModal, setShowModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  // const [currentCourse, setCurrentCourse] = useState<number | null>(null);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const currentUser = getCurrentUser();
  
  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading invoice', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setInvoice(data.invoice);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteInvoice(+params.id!).unwrap();
      setShowModal(false);
      navigate('/admin/finance/invoices');
      addToast('Invoice deleted successfully', 'success');
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };
  
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
   
    let checkValues = [...checkedValues];
    if (checked) {
      checkValues.push(id);
    } else {
      checkValues = checkValues.filter((value) => value !== id);
    }
    
    setCheckedValues(checkValues);
  };

  const sendInvoiceHandler=async ()=>{
   
    if(checkedValues.length===0){
      addToast('Select at least one email to send the invoice', 'error');
      return
    }
    setDisabledButton(true);
    const res = await sendInvoice({
      id: invoice?.id ?? 0,
      contact_ids: checkedValues
    }).unwrap();
      addToast('The invoice was sent', 'success');
    setDisabledButton(false);

      return
  }

  const warnInvoiceHandler=async ()=>{
    if (!checkedValues?.length) {
      addToast('Select at least one email to send the invoice', 'error');
      return
    }
    const res = await warnInvoice({
      id: invoice?.id ?? 0,
      contact_ids: checkedValues
    }).unwrap();

    addToast("The invoice was sent successfully", "success");
  }

  const handlePreviewInvoicePDF = async () => {
    downloadInvoice({ path: `invoices/generate-invoice/${params.id}` });
  };
  
  const accordion = (
    <SFCAccordion defaultActiveKey={['0', '1', '2', '3', '4']}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Sessions</Accordion.Header>
        <Accordion.Body>
          <SimpleTable columns={invoiceSessionsColumns} data={invoice?.courses ?? []} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Venues</Accordion.Header>
        <Accordion.Body>
          <Stack gap={2}>
            {invoice?.courses?.map((course, idx) => (
              <LinkCell key={idx} content={course.venue.title} url={`/admin/places/venues/${course.venue.id}`} underline />
            ))}
          </Stack>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Organizations</Accordion.Header>
        <Accordion.Body>
          <Stack gap={2}>
            {invoice?.courses?.map((course, idx) => course.venue.organizations.map((o) => (
              <LinkCell key={idx} content={o.name} url={`/admin/places/organizations/${o.id}`} underline />
            )))}
          </Stack>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Invoice Items</Accordion.Header>
        <Accordion.Body>
          <InvoiceItems  data={invoice?.invoice_items ?? []} isPaidInvoice={invoice?.status === 'paid'} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Payments</Accordion.Header>
        <Accordion.Body>
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Revisions</Accordion.Header>
        <Accordion.Body>
          <SimpleTable
                      columns={revisionsColumns.filter((column) => column.header !== 'Comment')}
                      data={invoice?.audits ?? []}
                    />
        </Accordion.Body>
      </Accordion.Item>
    </SFCAccordion>
  );

  const invoiceDetails = (
    <>
      <DetailSection title="Details">
        <LabelText label="ID:">
          {invoice?.id}
        </LabelText>
        <LabelText label="Invoice Name:">
          {invoice?.name}
        </LabelText>
        <LabelText label="Invoice Client:">
          {invoice?.invoice_client?.name}
        </LabelText>
        <LabelText label="Status:">
          <Badge title={invoice?.status ?? ''} color={invoiceStatusColors[invoice?.status ?? 'draft']} />
        </LabelText>
        <LabelText label="Issue On:">
          {invoice?.issue_on ? dateToUTC(new Date(invoice?.issue_on), 'MM/dd/yyyy') : 'N/A'}
        </LabelText>
        <LabelText label="Due On:">
          {invoice?.due_on ? dateToUTC(new Date(invoice?.due_on), 'MM/dd/yyyy') : 'N/A'}
        </LabelText>
        <LabelText label="Sent At:">
          {invoice?.sent_at ? dateToUTC(new Date(invoice?.sent_at), 'MM/dd/yyyy'): 'N/A'}
        </LabelText>
        <LabelText label="Paid At:">
          {invoice?.sent_at ? dateToUTC(new Date(invoice?.paid_at), 'MM/dd/yyyy'): 'N/A'}
        </LabelText>
        <LabelText label="Created At:">
          {invoice?.created_at ? formatDate(new Date(invoice?.created_at), 'MM/dd/yyyy h:mm a'): 'N/A'}
        </LabelText>
        <LabelText label="Total:">
          ${invoice?.total?.toFixed(2)}
      </LabelText>
        {userCanAdmin_Invoices(currentUser) && (
          <>
            <IconLink icon={<BsFiletypePdf size={24}/>} text="Preview the invoice PDF" onclick={handlePreviewInvoicePDF}/>
            <IconLink icon={<BsCreditCard size={24} />} text="Visit the client payment portal"  url={`/admin/finance/invoice_client/${invoice?.invoice_client_id}`}/>
            <IconLink icon={<BsCashCoin size={24} />} text="Make a manual payment" url={`/admin/finance/payments/new?invoice_id=${invoice?.id}`}/>
          </>
        )}
      </DetailSection>

      <DetailSection title="Contacts">
        {/* TODO: manage contacts sometimes uses the organization contacts instead of venues, need to investigate */}
        <LinkCell content="Manage Contacts" url={`/admin/places/venues/${invoice?.courses?.[0]?.venue?.id}`} underline />
        {invoice?.courses.some((c) => c.venue.contacts.length > 0)
          ? invoice?.courses.flatMap((c) => c.venue.contacts.map((contact, idx) => (
            <Form.Check
                      key={idx}
                      type="checkbox"
                      id={String(contact?.id)}
                      label={contact.email}
                      onChange={handleCheckboxChange}
                    />
          ))) 
          : <span className="fst-italic">There are no contacts to show.</span>
        }

{invoice?.courses?.[0]?.venue?.id && (
  <>
         <Button variant="warning" onClick={sendInvoiceHandler} disabled={disabledButton}>Send invoice</Button>
         <Button variant="danger" onClick={warnInvoiceHandler} disabled={disabledButton}>Send Past Due Notice</Button>
         </>
)}
      </DetailSection>

     

      <DetailSection title="Organization Notes">
        {invoice?.courses.map((c) => c.venue.organizations.map((o, idx) => (
          <LabelTextList key={idx} label={`${o.name} Notes`} list={[o.notes]} />
        )))}
      </DetailSection>

      <DetailSection title="Venue Notes">
      {invoice?.courses.map((c, idx) =>
        <LabelTextList key={idx} label={`${c.venue.title} Notes`} list={[c.venue.notes ?? 'No notes']} />)}
      </DetailSection>
    </>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/finance/invoices')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Invoices List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{invoice?.name}</h2>
        </Stack>

        {userCanAdmin_Invoices(currentUser) && (
          <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() => navigate(`/admin/finance/invoice-items/new?invoice=${invoice?.id}`)}
            >
              Add Invoice Item
            </SimpleButton>
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() => navigate(`/admin/finance/invoices/${invoice?.id}/edit`)}
            >
              Edit invoice
            </SimpleButton>
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() => setShowModal(true)}
            >
              Delete invoice
            </SimpleButton>
          </Stack>
        )}
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          {invoiceDetails}
          {accordion}
        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div className="mt-5 w-100 px-5 py-4" style={{ maxWidth: 'calc(100% - 400px)' }}>
          {accordion}
        </div>
        <Stack gap={4} className="p-4 vh-100" style={{ width: 400, background: '#F2F2F2' }}>
          {invoiceDetails}
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Invoice"
        message="Are you sure you want to remove this invoice?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};


