import React from 'react';
import { LessonPlan } from '../../../types/course.type';
import { LessonPlan as ThemeLessonPlan } from '../../../types/theme.type';
import { Stack } from 'react-bootstrap';
import { PiForkKnifeFill, PiStarFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';

interface LessonPlansCellProps {
  lessonPlans: LessonPlan[];
  featuredLessonPlans?: ThemeLessonPlan[];
}

export const LessonPlansCell: React.FC<LessonPlansCellProps> = ({ lessonPlans, featuredLessonPlans }) => {
  return (
    <Stack gap={1}>
      {featuredLessonPlans?.map((plan) => (
        <Link to={`../../../../lesson-plans/${plan.id}`} className="text-info">
          <Stack key={plan.id} direction="horizontal" gap={1}>
            <PiStarFill size={35} className="text-info" />
            <span>{plan.name}</span>
          </Stack>
        </Link>
      ))}
      {lessonPlans.map((plan) => (
          <Link to={`../../../../lesson-plans/${plan.id}`} className="text-info">
            <Stack key={plan.id} direction="horizontal" gap={1}>
              <PiForkKnifeFill size={45} className="text-info" />
              <span>{plan.name}</span>
            </Stack>
          </Link>
      ))}
    </Stack>
  )
}
