/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Container, Form, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { FadeIn } from '../../animations/FadeIn';
import { useSearchCoursesQuery } from '../../../services/endpoints/schedule/course';
import CourseSearchBar from './CourseSearchBar';
import CourseResults from './CourseResults';
import CourseMap from './CourseMap';
import { Filter, GetQueryParams } from '../../../types/api.type';
import { useNavbar } from '../../../context/DashNavbarContext';
import { FilterField, FilterForm } from '../../../components/tables/filterForms/FilterForm';
import { DateField } from '../../../components/tables/filterForms/DateField';
import { FormSelect, FormSelectOptions } from '../../../components/tables/filterForms/FormSelect';
import { FilterNavbar } from '../../navbar/FilterNavbar/FilterNavbar';
import { MultiFilterGroup } from '../../../components/filterGroup/MultiFilterGroup';
import { ButtonData } from '../../../components/buttonGroup/MultiSelectButtonGroup';
import { useGetColleaguesSimpleQuery } from '../../../services/endpoints/people/user';
import { useToast } from '../../../context/ToastContext';
import { CookingCampsMarker, EventsMarker, SchoolProgramMarker } from '../../maps/components';
import { CourseLink } from './CourseVenueDistance';
import { BsCaretRightFill } from 'react-icons/bs';
import { Course } from '../../../types/course.type';
import SelectedCourse from './SelectedCourse';
import { FaLocationDot } from 'react-icons/fa6';
import { StyleGreen } from './CourseDates';
import { useGetVenuesSimpleQuery } from '../../../services/endpoints/places/venue';
import { useGetThemesSimpleQuery } from '../../../services/endpoints/content/theme';
import { getCurrentDateOnly } from '../../../utils/dateUtils';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMyLocationContext } from '../../../context/LocationContext';
import { useDataContext } from '../../../context';
import { LatLng } from '../../../services/helper';
import "./CourseSearch.css";
import LocationModal from '../../../components/modals/LocationModal';

interface CourseSearchProps {}

export const getValueFromFilters = (field: string, operator?: string, filters?: Filter[]) => {
  return filters?.find(f => (f.field === field && (operator ? operator === f.operator : true)))?.value;
}

const sessionTypes = ['after_school', 'camps', 'events', 'online'];

const CourseSearch: React.FC<CourseSearchProps> = ({}) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { locationOptions, getLocationOptions } = useDataContext();
  const { myLocation: preferredLocation } = useMyLocationContext();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const defaultScope = 'upcoming';
  const [showModal, setShowModal] = useState(false);

  // searchParams
  const sessionType = searchParams.get("session_type") ?? '';
  const listView = (searchParams.get("view") ?? '') === "list";
  const search = searchParams.get("search");
  const origin = searchParams.get("origin");
  const location_id = searchParams.get("location_id");

  // if user searches with a location (origin), then use it, otherwise if they have a location set, use that, otherwise use no origin search
  const originFilter = (origin || preferredLocation?.location) ? {field: 'origin', operator: 'equals', value: origin || `${preferredLocation?.location.lat}_${preferredLocation?.location.lng}`} : undefined;
  const searchFilter = (search && search !== '') ? {field: 'search', operator: 'contains', value: search} : undefined;
  const locationFilter = (location_id && location_id !== '') ? {field: 'location_id', operator: 'equals', value: location_id} : undefined;
  
  const urlScope = sessionTypes.includes(sessionType) ? [sessionType] : [];
  const [scope, setScope] = useState<string[]>([defaultScope, ...urlScope]);
  const [buttons, setButtons] = useState<ButtonData[]>([
    {key: "after_school", title: "School Programs", icon: <SchoolProgramMarker height={20} width={20} />, btnVariant: 'outline-sfc-pink'},
    {key: "camps", title: "Cooking Camps", icon: <CookingCampsMarker height={20} width={20} />, btnVariant: 'outline-sfc-orange'},
    {key: "events", title: "Events", icon: <EventsMarker height={20} width={20} />, btnVariant: 'outline-sfc-green'},
  ]);
  const [filters, setFilters] = useState<GetQueryParams>({ 
    scope: defaultScope, 
    data: {
      page: 1, // remove this
      entries: 10, // remove this
      filterBy: [
        ...((originFilter && sessionType !== 'online') ? [originFilter] : []),
        ...((searchFilter) ? [searchFilter] : []),
        ...((locationFilter) ? [locationFilter] : []),
      ],
    } 
  });
  const [selectedLocations, setSelectedLocations] = useState<{location_ids: number[]}|undefined>();
  const {data: instructors, error: instructorsError, isLoading: instructorsLoading} = useGetColleaguesSimpleQuery(selectedLocations);
  const {data: themes, error: themeError, isLoading: themeLoading} = useGetThemesSimpleQuery();
  const {data: venues, error: venuesError, isLoading: venuesLoading} = useGetVenuesSimpleQuery(selectedLocations);
  const [ venueOptions, setVenueOptions ] = useState<FormSelectOptions[]>([]);

  const {data: courses, isLoading, error, isFetching} = useSearchCoursesQuery({...filters, scopes: scope});
  const [showDistance, setShowDistance] = useState<boolean>(sessionType !== 'online');

  useEffect(() => {
    if(!preferredLocation) {
      setShowModal(true);
    }
  }, []);
  useEffect(() => {
    if (venues) {
      setVenueOptions(venues.map(i => ({value: i.id, label: i.title})) ?? [])
    }
  }, [venues]);

  useEffect(() => {
    // console.log('filters', filters);
    if (filters) {
      let location_id_value = filters?.data?.filterBy?.find(f => f.field === 'location_id')?.value as unknown as (undefined|{label: string, value: number}[]);
      let location_ids = location_id ? [+location_id] : location_id_value?.map?.(s => s.value);
      setSelectedLocations((location_ids && location_ids.length > 0) ? {location_ids} : undefined);
    }
  }, [filters]);

  const toggleLocationFilter = () => {
    setFilters((old) => {
      let copy = JSON.parse(JSON.stringify(old)) as GetQueryParams;
      let originIndex = copy?.data?.filterBy?.findIndex(f => f.field === 'origin') ?? -1;
      if (originIndex === -1 && originFilter) {
        if (copy.data?.filterBy) {
          copy.data?.filterBy?.push(originFilter);
        }
        else {
          if (copy.data) {
            copy.data.filterBy = [originFilter];
          }
          else {
            copy.data = {filterBy: [originFilter]};
          }
        }
        setShowDistance(true);
      }
      else {
        copy.data?.filterBy?.splice(originIndex, 1);
        setShowDistance(false);
      }
      return copy;
    });
  }

  const getValueFromFilter = (field: string, operator?: string) => {
    return getValueFromFilters(field, operator, filters.data?.filterBy);
  }

  const filterFormFields: FilterField[] = [
    ...(sessionType === 'online' ? ([]) : ([{
      name: "origin",
      label: "Location",
      element: (
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <InputGroup>
            <Button size="sm" variant="outline-primary" className={`text-uppercase w-100 ${showDistance ? 'active' : ''}`} onClick={toggleLocationFilter}>
              <FaLocationDot size={16} style={StyleGreen} className={`me-2 mb-1 green`}/>
              {`${showDistance ? "Don't " : ""}Use My Location`}
            </Button>
          </InputGroup>
        </Form.Group>
      ),
    },
    {
      name: 'distance',
      label: 'Max Miles (Approximate) Default 20',
      operator: 'lesser_than',
      type: 'number',
      value: getValueFromFilter('distance')?.toString(),
      element: showDistance ? undefined : <></>,
    },
    {
      name: 'none',
      label: '',
      element: (
        <hr className="m-0" />
      )
    }])),
    // TODO: decide whether to include here as part of filter form or seprate out and use quick scope buttons on list view as well
    // {
    //   name: "course_type",
    //   element: (
    //     <FormSelect
    //       name="course_type"
    //       label="Session Type"
    //       value={sessionTypes?.includes(sessionType) ? {value: sessionType, label: titleCase(sessionType)} : undefined}
    //       options={sessionTypes?.map(i => ({value: i, label: titleCase(i)})) ?? []}
    //     />
    //   ),
    // },
    {
      name: 'location_id',
      operator: 'includes',
      value: getLocationOptions(location_id ?? ''),
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          multiple={true}
          options={locationOptions ?? []}
        />
      ),
    },
    {
      name: 'venue_id',
      label: 'Venue/School',
      element: (
        <FormSelect
          name="venue_id"
          label="School/Venue"
          // multiple={true} ? 
          options={venueOptions}
          loadingError={venuesError ? "Error loading school/venues" : undefined}
        />
      ),
    },
    {
      name: 'starts_from',
      id: 'starts_on',
      operator: 'greater_equal_than',
      value: getValueFromFilter('starts_on', 'greater_equal_than')?.toString(),
      element: (
        <DateField name="starts_from" label="Date: From" />
      )
    },
    {
      name: 'starts_to',
      id: 'starts_on',
      operator: 'lesser_equal_than',
      value: getValueFromFilter('starts_on', 'lesser_equal_than')?.toString(),
      element: (
        <DateField name="starts_to" label="To" min={getCurrentDateOnly()} />
      )
    },
    {
      name: 'instructor_id',
      type: 'number',
      element: (
        <FormSelect
          name="instructor_id"
          label="Instructor"
          options={instructors?.map(i => ({value: i.id, label: i.full_name ?? `${i.first_name} ${i.last_name}`})) ?? []}
          loadingError={instructorsError ? "Error loading instructors" : undefined}
        />
      ),
    },
    {
      name: 'theme_id',
      type: 'number',
      element: (
        <FormSelect
          name="theme_id"
          label="Theme"
          options={themes?.map(i => ({value: i.id, label: i.name})) ?? []}
          loadingError={themeError ? "Error loading themes" : undefined}
        />
      ),
    }
  ];

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    // TODO condense this and combine with other similar filter setting functions throughout this search component siblings
    if (filterBy.length === 0) {
      return;
    }
    let distanceIndex = filterBy.findIndex(f => f.field === 'distance');
    if (distanceIndex !== -1) {
      if (!preferredLocation?.location) {
        filterBy.splice(distanceIndex, 1);
      }
    }
    let courseTypeFilter = filters?.data?.filterBy?.find(f => f.field === 'course_type');
    let originFilter = filters?.data?.filterBy?.find(f => f.field === 'origin');
    let baseFilter = filterBy as Filter[];
    if (courseTypeFilter) {
      baseFilter.push(courseTypeFilter);
    }
    if (originFilter) {
      baseFilter.push(originFilter);
    }
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy: baseFilter,
      }
    }))
  };

  const resetFilters = (originalReset: () => void) => {
    let copy = JSON.parse(JSON.stringify(filters)) as GetQueryParams;
    let filter = copy?.data?.filterBy?.find(f => f.field === 'course_type');
    let filterBy = filter ? [filter] : undefined; 
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }));
    setShowDistance(false);
    originalReset();
  }

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[courses, error, isLoading]);

  if (isLoading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );

  return (
    <>
      <FilterNavbar />

      <CourseMapSearch 
        listView={listView}
        filterForm={<FilterForm
          fields={filterFormFields} 
          onApplyFilters={setFilterBy}
          reset={resetFilters}
        />}
        onFiltersChanged={setFilters}
        filters={filters}
        data={courses?.results}
        isLoading={isFetching}
        setScope={setScope}
        scope={scope}
        scopes={buttons}
      />

      <LocationModal
        show={showModal}
        setShow={setShowModal}
        title="Set Your Location"
        message="To search for Sticky Fingers Cookings Sessions near you, please select a location or use your current location."
        placeholder="Search City or Zip Code"
        onClose={() => {setShowModal(false); navigate("/")}}
      />
    </>
  )
}

interface CourseMapSearchProps {
  listView: boolean;
  filterForm?: ReactElement;
  onFiltersChanged?: React.Dispatch<React.SetStateAction<GetQueryParams>>;
  filters?: GetQueryParams;
  data?: Course[];
  scope: string[];
  setScope: React.Dispatch<React.SetStateAction<string[]>>;
  scopes?: ButtonData[];
  isLoading?: boolean;
}

const CourseMapSearch: React.FC<CourseMapSearchProps> = ({listView: showList, filterForm, onFiltersChanged, filters, data, scope, setScope, scopes, isLoading}) => {

  const { setFilterNavContent, setFilterNavOverlap } = useNavbar();
  const { myLocation: location } = useMyLocationContext();

  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [selectedLocation, setSelectedLocation] = useState<LatLng>();
  const [listView, setListView] = useState<boolean>(showList);


  useEffect(() => {
    const venue = selectedCourse?.venue;
    if (venue?.latitude && venue?.longitude) {
      setSelectedLocation({lat: venue?.latitude, lng: venue?.longitude});
    }
    else {
      setSelectedLocation(undefined);
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (filterForm) {
      setFilterNavContent(filterForm);
      setFilterNavOverlap(true);
    }
  }, [filterForm]);

  const toggleView = () => {
    setListView(!listView);
  }

  const toggleScope = (s: string) => {
    setScope((old) => {
      let copy = [...old];
      if (copy.includes(s)) {
        copy.splice(copy.indexOf(s), 1);
      }
      else {
        copy.push(s);
      }
      return copy;
    });
  }

  const useMyLocation = getValueFromFilters('origin', undefined, filters?.data?.filterBy);
  
  return (
    <FadeIn>
      <Row>
        <Col sm={12} className='course-search'>
          <Stack direction='horizontal' className='flex-wrap-mobile text-center-mobile'>
            <Stack className="m-3 find-sessions-text">
              <Breadcrumb className="text-uppercase">
                <Breadcrumb.Item href="#">Find A Cooking Session</Breadcrumb.Item>
                <Breadcrumb.Item href="#" active>{useMyLocation ? `Classes Near ${location?.label}` : 'All Classes'}</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="text-info"><b>Find cooking classes near you!</b></h1>
              <h3 className="text-muted">Everywhere you want us to be</h3>
            </Stack>
            <div className='ms-auto'>
              <CourseSearchBar 
                filters={filters} 
                setFilters={onFiltersChanged}
              />
            </div>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Stack className='me-2'>
          <div className={'ms-auto'}>
            <CourseLink url={'/nationwide'} content={<span className="text-uppercase" style={{fontSize: '20px'}}>Find Us in more locations nationwide <BsCaretRightFill style={{top: '-1px', position: 'relative'}} /></span>} color={'blue'} />
          </div>
        </Stack>
        <Col lg={listView ? 12 : 5}>
          <Link
            className="ms-3 text-info fw-bold"
            style={{ textDecoration: 'none' }}
            to={"#"}
            onClick={toggleView}
          >
            <span className="text-uppercase">{listView ? 'map' : 'list'} view</span>
          </Link>
          <SelectedCourse course={selectedCourse} onClose={() => setSelectedCourse(undefined)} />
          {isLoading && <span className="ms-3">Loading ... <Spinner size="sm" /></span>}
          <CourseResults listView={listView} courses={data ?? []} onClick={(course: Course) => setSelectedCourse(course)}/>
        </Col>
        {!listView && (
          <Col lg={7}>
            <Container>
              <MultiFilterGroup
                filterButtons={scopes}
                onFilterSelect={(filter: string) => {
                  toggleScope(filter);
                }}
              />
            </Container>
            <CourseMap 
              courses={data ?? []} 
              location={selectedLocation} 
              onVenueClick={setSelectedCourse} 
              selectedCourseId={selectedCourse?.id}
            />
          </Col>
        )}
      </Row>
    </FadeIn>
  );
};

export default CourseSearch;