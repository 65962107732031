import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { sessionStatus, sessionTypes } from '../../../../../utils/consts/schedule/sessions';
import { needSubClassesColumns } from '../../../../../utils/consts/columnDefs/classColumns';
import { Lesson, LessonAssignment } from '../../../../../types/lesson.type';
import { getCurrentUser } from '../../../../../services/helper';
import { useGetLessonAssignmentsQuery, useTeachClassMutation } from '../../../../../services/endpoints/schedule/lessonAssignment';
import React from 'react';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { ColumnDef } from '@tanstack/react-table';
import { Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { User, useGetUsersQuery } from '../../../../../services/endpoints/people/user';
import { useDataContext } from '../../../../../context';
import { TimeField } from '../../../../../components/tables/filterForms/TimeField';
import { useNavigate } from 'react-router-dom';
import { DateFilterField } from '../../../../../components/tables/filterForms/DateFilterField';
const defaultScope = 'relevant';
const currentUser = getCurrentUser();

// TODO: add status = 'open' for filtering, check if assign instructor column is required
const defaultSort = {
  order: {field: 'lesson_date', order: 'asc'},
  sort: {id: 'lesson_date', desc: false},
}

export const userCanView_NeedSubstitute = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const NeedSubstituteView = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: { entries: 10, page: 1, orderBy: defaultSort.order } });
  const isGlobalUser = (currentUser?.isGlobal);
  const isAdminUser = (currentUser?.isAdmin);
  const { data, error, isLoading, isFetching } = useGetLessonAssignmentsQuery(filters);
  const [ teachClass ] = useTeachClassMutation();
  const { data: instructors, error: instructorsError, isLoading: instructorsLoading, isFetching: instructorsFetching } = useGetUsersQuery({ scope: 'colleagues', data: {orderBy: {field: 'first_name', order: 'asc'}} }, {skip: !isAdminUser});
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const usesAdvancedScheduling = (currentUser?.uses_advanced_scheduling === true);

  const filterFormFields: FilterField[] = [
    {
      name: 'course_title',
      label: 'Session',
      // element: (
      //   <SearchField name="course_title" label="Session" placeholder="Search session" />
      // )
    },
    {
      name: 'main_instructor_full_name',
      label: 'Instructor',
      // element: (
      //   <SearchField name="main_instructor_full_name" label="Instructor" placeholder="Search main instructor" />
      // )
    },
    {
      name: 'course_day_of_week',
      label: 'Day of Week',
    },
    {
      name: 'time_from',
      id: 'lesson_time',
      operator: 'greater_equal_than',
      element: <TimeField name="time_from" label="Lesson time - From" />
    },
    {
      name: 'time_to',
      id: 'lesson_time',
      operator: 'lesser_equal_than',
      element: <TimeField name="time_to" label='To' />
    },
    // {
    //   name: 'status',
    //   element: (
    //     <FormSelect
    //       name="status"
    //       label="Status"
    //       options={Object.entries(sessionStatus).map((status) => ({ value: status[0], label: status[1] }))}
    //     />
    //   ),
    // },
    // {
    //   name: 'course_type',
    //   element: (
    //     <FormSelect
    //       name="course_type"
    //       label="Session Type"
    //       options={Object.entries(sessionTypes).map((type) => ({ value: type[0], label: type[1] }))}
    //     />
    //   ),
    // },
    {
      name: 'starts_from',
      id: 'lesson_date',
      operator: 'greater_equal_than',
      element: (
        <DateFilterField name="starts_from" label="Lesson Date" />
      )
    },
    {
      name: 'starts_to',
      id: 'lesson_date',
      operator: 'lesser_equal_than',
      element: (
        <DateFilterField name="starts_to" />
      )
    },
    // {
    //   name: 'ends_from',
    //   id: 'ends_on',
    //   operator: 'greater_equal_than',
    //   element: <DateField name="ends_from" label="End Date" />
    // },
    // {
    //   name: 'ends_to',
    //   id: 'ends_on',
    //   operator: 'lesser_equal_than',
    //   element: <DateField name="ends_to" />
    // },
    // {
    //   name: 'time_from',
    //   id: 'time',
    //   operator: 'greater_equal_than',
    //   element: <TimeField name="time_from" label="Lesson time - From" />
    // },
    // {
    //   name: 'time_to',
    //   id: 'time',
    //   operator: 'lesser_equal_than',
    //   element: <TimeField name="time_to" label='To' />
    // },
  ];

  if(currentUser?.isGlobal) {
    filterFormFields.push({
      name: 'location_id',
      label: 'Location',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    });
  }
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading schedule needs', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setLessons(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  const tableValidationSchema = Yup.object({
    lesson_assignment_id: Yup.number().integer().min(1).required(),
    instructor: Yup.object({value: Yup.number().min(1, "Instructor ID is required"), label: Yup.string().optional()}).optional(),
  })
  
  let pickupCol: ColumnDef<LessonAssignment> = 
  {
    header: '',
    accessorKey: 'blank',
    enableSorting: false,
    cell: ({ row }) => (
      <React.Fragment> 
        <FormikForm >
          {
            canScheduleFor(row.original.lesson.course.venue.location_id) ? <FormSelect
              name="instructor"
              label=""
              options={instructors?.results?.map((i: User) => ({label: i.full_name ?? `${i.first_name} ${i.last_name}`, value: i.id})) ?? []}
              loadingError={instructorsError ? "Error loading instructors" : undefined}
            /> : <></>
          }
          {
            (canScheduleFor(row.original.lesson.course.venue.location_id) || !isGlobalUser) ? (
              <SimpleButton 
                type="submit"
                height={30} 
                className="text-center btn-sm" 
                variant="primary">
                Pick Up Class
              </SimpleButton>
            ) : (
              <></>
            )
          }
        </FormikForm>
      </React.Fragment>
    )
  };

  const canScheduleFor = (location_id: number) => {
    return (isAdminUser && usesAdvancedScheduling && (currentUser?.location_id === location_id || isGlobalUser));
  }

  const pickupClass = (values: {lesson_assignment_id: number, instructor?: {value: number}}) => {
    let data = {
      id: values.lesson_assignment_id,
      instructor_id: values.instructor?.value,
    }
    teachClass(data).unwrap().then(() => {
      navigate('/admin/schedule/sessions/' + lessons.find((l: Lesson) => l.id === values.lesson_assignment_id)?.course_id);
      addToast("The class has been picked up.", "success");
    }).catch((error: any) => {
      // console.log(error);
      addToast(`Failed to pick up class: ${JSON.stringify(error)}`, "error");
    });
  }

  return (
    <div className="py-2">
      <SFCTable
        name={`Available Classes`}
        columns={needSubClassesColumns.concat(pickupCol)}
        data={lessons}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        defaultSort={defaultSort.sort}
        filters={filters?.data?.filterBy}
        indexDownloadPath='lesson-assignments'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        rowFormProps={{
          validationSchema: tableValidationSchema,
          onSubmit: pickupClass,
          getInitialValues: (row) => ({
            lesson_assignment_id: row.original.id,
          }),
        }}
      />
    </div>
  );
}
